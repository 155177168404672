(function () {
  'use strict';

  angular
    .module('users.edit')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('settings.users.edit', {
        url: '/:userId/edit',
        templateUrl: 'settings/users/edit/edit.tpl.html',
        controller: 'UserEditCtrl',
        controllerAs: 'vm'
      })
      .state('settings.users.add', {
        url: '/add',
        templateUrl: 'settings/users/edit/edit.tpl.html',
        controller: 'UserEditCtrl',
        controllerAs: 'vm'
      })
    ;
  }
}());
